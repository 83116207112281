<template>
    <v-app v-cloak style="height:100%;" v-scroll.self="onScroll">
        <AppBar height="105px"></AppBar>
        <v-sheet class="overflow-y-auto d-print-block"
                 v-scroll.self="onScroll"
                 style="min-height: 100%"
                 id="scrolling-techniques">
            <div id="topScroll" ref="target"></div>

            <keep-alive>
                <v-main class="overflow-y-hidden scroll-y customcontainer d-print-block">
                    <router-view v-keep-scroll-position></router-view>
                </v-main>

            </keep-alive>
            <v-snackbar :color="snackColor" v-model="snackVisible" :timeout="4000" top right class="d-print-none">
                {{ snackText }}
            </v-snackbar>
            <v-snackbar text outlined bottom right v-model="isRefresh" :timeout="-1" color="primary" class="d-print-none">
                An update is available
                <template v-slot:action="{ attrs }">
                    <v-btn color="info" @click="update">
                        Update<v-icon>{{refresh}}</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
            <v-btn v-show="fab"
                   aria-label="Scroll To Top"
                   fab
                   dark
                   fixed
                   bottom
                   right
                   outlined
                   color="black"
                   class="d-print-none"
                   @click="toTop()">
                <v-icon>{{keyboard_arrow_up}}</v-icon>
            </v-btn>
            <v-footer min-height="52px" color="grey darken-1">
                <v-layout justify-center row wrap style="margin:0">
                    <v-flex white--text xs12 sm12 md4 pl-md-4 class="body link caption text-truncate" text-xs-center>
                        <div>
                            Legislative Research Council<br />
                            500 East Capitol Avenue | Pierre, SD 57501
                        </div>
                    </v-flex>
                    <v-flex white--text xs12 sm12 md4 class="body link caption text-truncate" text-md-center>
                        <div>
                            &copy; {{(new Date()).getFullYear()}} - SD Legislative Research Council<br />
                            <router-link to="/">LRC Homepage</router-link> | <a rel="noopener" href="https://sd.gov">SD Homepage</a>
                        </div>
                    </v-flex>
                    <v-flex white--text xs12 sm12 md4 class="body link caption text-truncate pr-4" text-md-right>
                        <div>
                            <router-link to="/PressRelease">Press Releases</router-link> |
                            <router-link to="/Staff">LRC Staff</router-link> |
                            <router-link to="/ContactUs/">Contact Us</router-link> |
                            <router-link to="/Disclaimer">Disclaimer</router-link>
                        </div>
                    </v-flex>
                </v-layout>
            </v-footer>
        </v-sheet>
    </v-app>
</template>

<script>
    var comp;
    const AppBar = () => import("@/Menus/AppBar.vue");
    import moment from 'moment';
    import { mdiChevronUp } from '@mdi/js';
    import { mdiRefresh } from '@mdi/js';

    export default {
        name: 'App',
        components: {
            AppBar
        },
        metaInfo: {
            // Children can override the title.
            title: '',
            // Result: Home | South Dakota Legislature
            // If a child changes the title to "My Other Page Title",
            // it will become: My Other Page Title | My Site
            titleTemplate: '%s | South Dakota Legislature',
            // Define meta tags here.
            meta: [
                //{vmid: 'description', name: 'description', content: 'The South Dakota State Legislature is the legislative branch of the government of South Dakota. It is a bicameral legislative body, consisting of the South Dakota Senate, which has 35 members, and the South Dakota House of Representatives, which has 70 members.'},
                // OpenGraph data (Most widely used)
                //{vmid: 'og:title', property: 'og:title', content: 'Home | South Dakota Legislature'},
                { vmid: 'og:site_name', property: 'og:site_name', content: 'South Dakota Legislature' },
                // The list of types is available here: http://ogp.me/#types
                { vmid: 'og:type', property: 'og:type', content: 'website' },
                // Should the the same as your canonical link, see below.
                { vmid: 'og:url', property: 'og:url', content: 'https://sdlegislature.gov' },
                { vmid: 'og:image', property: 'og:image', content: 'https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/logoshape.webp' },
                // Often the same as your meta description, but not always.
                //{vmid: 'og:description',property: 'og:description', content: 'The South Dakota State Legislature is the legislative branch of the government of South Dakota. It is a bicameral legislative body, consisting of the South Dakota Senate, which has 35 members, and the South Dakota House of Representatives, which has 70 members.'},

                // Twitter card
                { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                { vmid: 'twitter:site', name: 'twitter:site', content: 'https://sdlegislature.gov' },
                //{vmid: 'twitter:title', name: 'twitter:title', content: 'Home | South Dakota Legislature'},
                //{vmid: 'twitter:description', name: 'twitter:description', content: 'The South Dakota State Legislature is the legislative branch of the government of South Dakota. It is a bicameral legislative body, consisting of the South Dakota Senate, which has 35 members, and the South Dakota House of Representatives, which has 70 members.'},
                // Your twitter handle, if you have one.
                //{vmid: 'twitter:creator', name: 'twitter:creator', content: '@'}
                { vmid: 'twitter:image:src', name: 'twitter:image:src', content: 'https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/logoshape.webp' },

                // Google / Schema.org markup:
                //{vmid: 'Schema:name', itemprop: 'name', content: 'Home | South Dakota Legislature'},
                //{vmid: 'Schema:description', itemprop: 'description', content: 'The South Dakota State Legislature is the legislative branch of the government of South Dakota. It is a bicameral legislative body, consisting of the South Dakota Senate, which has 35 members, and the South Dakota House of Representatives, which has 70 members.'},
                { vmid: 'Schema:image', itemprop: 'image', content: 'https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/logoshape.webp' }
            ],
            link: [
                //    { vmid: 'canonical', rel: 'canonical', href: 'https://sdlegislature.gov' }
            ]
        },
        data() {
            return {
                refresh: mdiRefresh,
                keyboard_arrow_up: mdiChevronUp,
                registration: null,
                isRefresh: false,
                refreshing: false,
                loading: false,
                snackColor: 'success',
                snackVisible: false,
                snackText: null,
                allMenuItems: [
                    {
                        Title: 'Legislators',
                        active: false,
                        SubMenuItems: [
                            {
                                Title: 'Legislator Contacts',
                                url: '/Legislators/Contacts/',
                                includeSessionId: true,
                                includeYear: false,
                            },
                            {
                                Title: 'Find My Legislators',
                                url: '/Legislators/Find',
                                includeYear: false,
                            },
                            {
                                Title: 'Legislator Listing',
                                url: '/Legislators/Listing/',
                                includeSessionId: true,
                                includeYear: true,
                            },

                            {
                                Title: 'Historical Listing',
                                url: '/Legislators/Historical',
                                includeYear: false,
                            },
                            {
                                Title: 'Legislator Term Limits',
                                url: 'https://mylrc.sdlegislature.gov/api/Documents/Resource/72128.pdf?Year=2021',
                                includeFileType: '.pdf',
                                includeYear: false,
                            },
                            {
                                Title: 'Address List',
                                url: '/api/SessionMembers/',
                                includeYear: false,
                                includeSessionId: true,
                                includeFileType: '.csv',
                            },

                            {
                                Title: 'Archived',
                                url: '/Session/Archived',
                                includeYear: false,
                            }
                        ],
                    },
                    {
                        Title: 'Session',
                        active: false,
                        SubMenuItems: [
                            {
                                Title: 'Bills',
                                url: '/Session/Bills/',
                                includeYear: true,
                                includeSessionId: true,
                            },
                            {
                                Title: 'Chambers/Committees',
                                url: '/Session/Committees/',
                                includeYear: true,
                                includeSessionId: true,
                            },
                            {
                                Title: 'Conference Committees',
                                url: '/Session/ConferenceCommittees/',
                                includeYear: true,
                                includeSessionId: true,
                            },
                            {
                                Title: 'General',
                                url: '/Session/General/',
                                includeYear: true,
                                includeSessionId: true,
                            },
                            {
                                Title: 'Schedule',
                                url: '/Session/Schedule',
                                includeSessionId: false,
                                includeYear: true,
                                hideSpecialSession: true,
                            },
                            {
                                Title: 'Archived',
                                url: '/Session/Archived',
                                includeYear: false,
                            }
                        ],
                    },
                    {
                        Title: 'Interim',
                        active: false,
                        SubMenuItems: [
                            {
                                Title: 'Committees',
                                url: '/Interim/Committees/',
                                includeSessionId: true,
                                includeYear: true,
                                checkStartDate: true,
                            },
                            {
                                Title: 'Schedule',
                                url: '/Interim/Schedule',
                                includeSessionId: false,
                                includeYear: true,
                                checkStartDate: true,
                            },
                            {
                                Title: 'Rules of Procedure',
                                url: 'https://mylrc.sdlegislature.gov/api/Documents/RulesOfProcedure/72129.pdf?Year=2020',
                                includeFileType: '.pdf',
                                includeYear: false,
                            },
                            {
                                Title: 'Archived',
                                url: '/Interim/Archived',
                                includeYear: false,
                            }
                        ],
                    },
                    {
                        Title: 'Laws',
                        active: false,
                        SubMenuItems: [
                            {
                                Title: 'Constitution',
                                url: '/Constitution',
                                includeYear: false,
                            },
                            {
                                Title: 'Codified Laws',
                                url: '/Statutes',
                                includeYear: false,
                            },
                            {
                                Title: 'Session Laws',
                                url: '/Statutes/Session_Laws/',
                                includeSessionId: true,
                                includeYear: true,
                                checkStartDate: true,
                                addSixMonths: true,
                            },
                            {
                                Title: 'Archived',
                                url: '/Session/Archived',
                                includeYear: false,
                            }
                        ],
                    },
                    {
                        Title: 'Administrative Rules',
                        active: false,
                        SubMenuItems: [
                            {
                                Title: 'Administrative Rules List',
                                url: '/Rules/Administrative',
                                includeYear: false,
                            },
                            {
                                Title: 'Current Register',
                                url: 'https://mylrc.sdlegislature.gov/api/Documents/208938.pdf',
                                includeFileType: '.pdf',
                                includeYear: false,
                            },
                            {
                                Title: 'Archived Registers',
                                url: '/Rules/Registers/',
                                includeYear: false,
                                includeSessionId: true,
                                checkStartDate: true,


                            },
                            {
                                Title: 'Administrative Rules Manual',
                                url: '/Rules/RulesManual',
                                includeYear: false,
                            },
                            {
                                Title: 'Rules Review Committee',
                                url: '/Interim/Committee/424',
                                includeYear: false,
                            },
                            {
                                Title: 'Rules.sd.gov',
                                url: 'http://rules.sd.gov',
                                includeYear: false,
                            },
                            {
                                Title: 'Administrative Rule Process',
                                url: 'https://mylrc.sdlegislature.gov/api/Documents/IssueMemo/124666.pdf?Year=2017',
                                includeFileType: '.pdf',
                                includeYear: false,
                            }
                        ]
                    },
                    {
                        Title: 'Budget',
                        active: false,
                        SubMenuItems: [
                            {
                                Title: 'Hearing Documents',
                                url: '/Budget/Documents',
                                includeYear: false,
                            },
                            {
                                Title: 'Monthly Reports',
                                url: '/Budget/MonthlyReport',
                                includeYear: false,
                            },
                            {
                                Title: 'Budget Resources',
                                url: '/Budget/Resources',
                                includeYear: false,
                            },
                        ]
                    },
                    {
                        Title: 'Students',
                        active: false,
                        SubMenuItems: [
                            {
                                Title: 'Page Program',
                                url: '/Students/Page',
                                includeYear: false,
                            },
                            {
                                Title: 'Intern Program',
                                url: '/Students/Intern',
                                includeYear: false,
                            },
                            {
                                Title: 'Resources',
                                url: '/Students/Resources',
                                includeYear: false,
                            },
                        ],
                    },
                    {
                        Title: 'References',
                        active: false,
                        SubMenuItems: [
                            {
                                Title: 'Drafting Manual',
                                url: 'https://mylrc.sdlegislature.gov/api/Documents/127102.pdf',
                                includeFileType: '.pdf',

                                includeYear: false,
                            },
                            {
                                Title: 'Issue Memos / White Papers',
                                url: '/References/IssueMemos',
                                includeYear: false,
                            },
                            {
                                Title: 'Legislator Reference Book',
                                url: 'https://mylrc.sdlegislature.gov/api/Documents/171806.pdf',
                                includeFileType: '.pdf',
                                includeYear: false,
                            },
                            {
                                Title: 'Maps',
                                url: '/References/Maps',
                                includeYear: false,
                            },
                            {
                                Title: 'Performance Management Reports',
                                url: '/References/PerformanceReports',
                                includeYear: false,
                            },
                            {
                                Title: 'Program Evaluations',
                                url: '/References/ProgramEvaluation',
                                includeYear: false,
                            },
                            {
                                Title: 'Required Reports',
                                url: '/References/RequiredReports',
                                includeYear: false,
                            },
                            {
                                Title: 'Statistical Comparisons',
                                url: '/References/StatisticalComparison',
                                includeYear: false,
                            },
                            {
                                Title: 'External Resources',
                                url: '/References/ExternalResources',
                                includeYear: false,
                            },
                            {
                                Title: 'Public Resources',
                                url: '/References/PublicResources',
                                includeYear: false,
                            },
                        ],
                    },
                    {
                        Title: 'MyLRC +',
                        url: 'https://mylrc.sdlegislature.gov',
                        active: false,
                        SubMenuItems: [],
                    },
                ],
                searchURL: '',
                fab: false,
            }
        },
        mounted: function () {
            this.$MenuItems.value = this.allMenuItems;

            this.LoadData();

            if (document.URL.toLowerCase().indexOf('printerfriendly=true') > -1) {
                for (let i = 0; i < document.styleSheets.length; i++) {
                    for (var j = document.styleSheets[i].rules.length - 1; j >= 0; j--) {
                        if (document.styleSheets[i].rules[j].cssText.indexOf("@media print") != -1) {
                            document.styleSheets[i].insertRule(document.styleSheets[i].rules[j].cssText.replace('@media print', '@media screen'), j + 1);
                        }
                    }
                }
            }
        },
        created() {
            document.addEventListener(
                'serviceWorkerUpdateEvent', this.appUpdateUI, { once: true }
            );
            navigator.serviceWorker.addEventListener(
                'controllerchange', () => {
                    if (this.refreshing) return;
                    this.refreshing = true;
                    this.loading = true;
                    window.location.reload();
                }
            );
            //check for Navigation Timing API support
            if (window.performance) {
                if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
                    console.info("This page is reloaded");
                    this.update();
                } else {
                }
            }
        },
        methods: {
            appUpdateUI: function (e) {
                let registration = e.detail;

                if (registration && registration.waiting)
                {
                    this.loading = true;
                    console.info("Service Worker Called to Skip waiting");
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                }
            },
            update() {
                this.isRefresh = false;
                if (this.registration && this.registration.waiting) {
                    this.loading = true;
                    console.info("Service Worker Called to Skip waiting");
                    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                }
            },
            onFocus: function () {
                this.SearchBar = true;
            },
            LoadData: function () {
                comp = this;
                fetch('/api/Sessions', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(function (res) {
                    comp.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    throw new Error(res.statusText);
                }).then(function (response) {
                    comp.$Sessions = EncodeDatetimes(response);
                }).catch(function (error) {
                    throw new Error(error);
                });

                fetch('/api/Environment', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(function (res) {
                    comp.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    throw new Error(res.statusText);
                }).then(function (response) {
                    comp.$Environment.value = response;
                    comp.MyLRCUrl();
                }).catch(function (error) {
                    throw new Error(error);
                });

                fetch('/api/Documents/Menu', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(function (res) {
                    comp.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    throw new Error(res.statusText);
                }).then(function (response) {
                    comp.$menuDocuments.value = EncodeDatetimes(response);
                    comp.$MenuItems.value = comp.allMenuItems;
                    comp.$forceUpdate();
                }).catch(function (error) {
                    throw new Error(error);
                });

                fetch('/api/Documents/SideMenu', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(function (res) {
                    comp.loading = false;
                    if (res.ok) {
                        return res.json();
                    }
                    throw new Error(res.statusText);
                }).then(function (response) {
                    comp.$sideMenuDocuments.value = EncodeDatetimes(response);
                }).catch(function (error) {
                    throw new Error(error);
                });


                fetch('/api/MyLRC/LoggedIn', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(function (res) {
                    comp.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }

                }).then(function (response) {
                    if (response != null) {
                        comp.$LoggedIn = EncodeDatetimes(response);
                    }


                }).catch(function (error) {
                    throw new Error(error);
                });


            },
            checkStartDate(item) {
                if (comp.$Sessions) {
                    var s = comp.$Sessions.filter(x => x.SpecialSession == false);
                    var d = new Date();
                    if (item.addSixMonths) {
                        d = new Date(d.setMonth(d.getMonth() + 6));
                    }


                    if (s) {
                        s = s.sort(function (a, b) { return (a.StartDate > b.StartDate ? -1 : 1) });
                        if (s[0].StartDate <= d) {
                            return s[0].SessionId;
                        } else {
                            return s[1].SessionId;
                        }
                    }

                }

            },
            checkStartDateForTitle(item) {
                if (this.$Sessions) {
                    var s = this.$Sessions.value.filter(x => x.SpecialSession == false);
                    var d = new Date();
                    if (item.addSixMonths) {
                        d = new Date(d.setMonth(d.getMonth() + 6));
                    }
                    if (s) {
                        s = s.sort(function (a, b) { return (a.StartDate > b.StartDate ? -1 : 1) });
                        if (s[0].StartDate <= d) {
                            return s[0].YearString;
                        } else {
                            return s[1].YearString;
                        }
                    }
                }
                else {
                    return '';

                }

            },
            onScroll: function (e) {
                if (typeof window === 'undefined') return

                try {
                    let ss = null;

                    try {
                        ss = window.sessionStorage;
                    }
                    catch { }

                    if (ss) {
                        const top = window.pageYOffset || e.target.scrollTop || 0
                        this.fab = top > 20

                        var scrollPositions = ss['scrollPositions'] || '[]';

                        var temp = JSON.parse(scrollPositions);
                        if (temp.some(x => x.path == this.$router.currentRoute.path)) {
                            temp.find(x => x.path == this.$router.currentRoute.path).y = e.target.scrollTop;
                        } else {
                            var pos = {
                                path: this.$router.currentRoute.path,
                                y: e.target.scrollTop
                            };
                            temp.push(pos);
                        }

                        ss['scrollPositions'] = JSON.stringify(temp);
                    }
                }
                catch {

                }
            },
            toTop: function () {
                let elmnt = document.getElementById("topScroll");
                if (elmnt) {
                    elmnt.scrollIntoView();
                }
            },
            ShowMessage: function (message, color) {
                this.snackColor = color || 'info';
                this.snackText = message;

                this.snackVisible = true;
            },
            MyLRCUrl() {
                if (this.$Environment.value == "Development") {
                    this.$MyLRCUrl.value = "https://devmylrc.sdlegislature.gov/";
                }
                else if (this.$Environment.value == "Test") {
                    this.$MyLRCUrl.value = "https://testmylrc.sdlegislature.gov/";
                }
                else if (this.$Environment.value == "Production") {
                    this.$MyLRCUrl.value = "https://mylrc.sdlegislature.gov/";
                }
                else {
                    this.$MyLRCUrl.value = "https://mylrc.sdlegislature.gov/";
                }
            },
            Session() {
                if (comp.$Sessions.length > 1) {
                    comp.$Session = comp.$Sessions.filter(function (x) { return x.Visible <= Date.now() }).sort(function (a, b) { return (a.Visible > b.Visible ? -1 : 1) })[0];
                }
                else if (comp.$Sessions.length == 1)
                { 
                    comp.$Session = comp.$Sessions[0];
                }
            },
        },
        watch: {
            $route: function (to, from) {
            }
        },
        computed:
        {
            MenuItemsNoMylrc: function () {
                return this.$MenuItems.value.filter(x => x.Title != 'MyLRC +');
            },
        },
    };

    window.myfetch = function (resource, init) {
        init = init || {};
        init.credentials = 'same-origin';

        return fetch(resource, init).then(function (response) {
            if (response.status == 405)
                document.location.assign(this.$BasePath);
            else
                return response;
        });
    };

    window.MemberType = function (value) {
        if (value == 'S') {
            return 'Senator'
        }
        else {
            return 'Representative'
        }
    }

    window.isValidDate = function (date) {
        return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    }

    window.EncodeDatetimes = function (items) {
        if (items == null) {
            return null;
        }
        if (items.constructor === Array) {
            if (items.length > 0 && typeof items[0] === 'string') {
                for (var i = 0; i < items.length; i++) {
                    if (moment(items[i], moment.ISO_8601, true).isValid()) {
                        items[i] = new Date(items[i]);
                    }
                }
            }
            else {
                for (var item in items) {
                    EncodeDatetimes(items[item]);
                }
            }
        }
        else if (typeof items === 'string') {
            if (moment(items, moment.ISO_8601, true).isValid()) {
                items = new Date(items);
            }
        }
        else {
            for (var property in items) {
                if (items.hasOwnProperty(property)) {
                    if (typeof items[property] === 'string' || items[property] instanceof String) {
                        if (moment(items[property], moment.ISO_8601, true).isValid()) {
                            items[property] = new Date(items[property]);
                        }
                    }
                    else if (typeof items[property] === 'object' && items[property] !== null) {
                        EncodeDatetimes(items[property]);
                    }
                }
            }
        }
        return items;
    }

    window.EncodeDate = function (items) {
        if (items == null) {
            return null;
        }
        if (items.constructor === Array) {
            if (items.length > 0 && typeof items[0] === 'string') {
                for (var i = 0; i < items.length; i++) {
                    if (moment(items[i], moment.ISO_8601, true).isValid()) {
                        items[i] = moment(items[i], "YYYY-MM-DDTHH:mm:ss").toDate();
                    }
                }
            }
            else {
                for (var item in items) {
                    EncodeDate(items[item]);
                }
            }
        }
        else {
            if (typeof items === 'string' || items instanceof String) {
                if (moment(items, moment.ISO_8601, true).isValid()) {
                    items = moment(items, "YYYY-MM-DDTHH:mm:ss").toDate();
                }
            }
            else {
                for (var property in items) {
                    if (items.hasOwnProperty(property)) {
                        if (typeof items[property] === 'string' || items[property] instanceof String) {
                            if (moment(items[property], moment.ISO_8601, true).isValid()) {
                                items[property] = moment(items[property], "YYYY-MM-DDTHH:mm:ss").toDate();
                            }
                        }
                        else if (typeof items[property] === 'object' && items[property] !== null) {
                            EncodeDate(items[property]);
                        }
                    }
                }
            }
        }
        return items;
    }
    //https://github.com/vuejs-tips/tiny-debounce
    window.debounce = function debounce(fn, delay) {
        var timeoutID = null;
        return function () {
            clearTimeout(timeoutID)
            var args = arguments
            var that = this
            timeoutID = setTimeout(function () {
                fn.apply(that, args)
            }, delay)
        }
    }

</script>

<style scoped>
</style>
﻿import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

Vue.use(Router);
Vue.use(Meta);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import("@/Home/Home.vue"),
        },
        {
            path: '/index',
            redirect: '/',
        },
        {
            path: '/index.html',
            redirect: '/',
        },
        {
            path: '/Session/BillStatus/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/BillStatus.vue"),
        },
        {
            path: '/Session/Bill/:BillId',
            props: true,
            component: () => import("@/Session/Bill.vue"),
        },
        {
            path: '/Session/Bill/:BillId/:DocumentId',
            props: true,
            component: () => import("@/Session/BillHTML.vue"),
        },
        {
            path: '/Session/Keyword/:SubjectId',
            redirect: '/Session/Subject/:SubjectId',
            props: true,
        },
        {
            path: '/Session/Subject/:SubjectId',
            props: true,
            component: () => import("@/Session/Subject.vue"),
        },
        {
            path: '/Session/TextSearch/:SessionId',
            props: true,
            component: () => import("@/Session/TextSearch.vue"),
        },
        {
            path: '/Session/SubjectIndex/:SessionId',
            props: true,
            component: () => import("@/Session/SubjectIndex.vue"),
        },
        {
            path: '/Session/BillReports/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/SignedByGovernor.vue"),
        },
        {
            path: '/Session/BillReports/SignedByGovernor/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/SignedByGovernor.vue"),
        },
        {
            path: '/Session/BillReports/VetoedByGovernor/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/VetoedByGovernor.vue"),
        },
        {
            path: '/Session/BillReports/GovernorsDesk/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/GovernorsDesk.vue"),
        },
        {
            path: '/Session/BillReports/Custom/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/Custom.vue"),
        },
        {
            path: '/Session/BillReports/FiscalNotes/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/FiscalNotes.vue"),
        },
        {
            path: '/Session/BillReports/Hoghouse/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/Hoghouse.vue"),
        },
        {
            path: '/Session/BillReports/PrisonJail/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/PrisonJail.vue"),
        },
        {
            path: '/Session/BillReports/Withdrawn/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/Withdrawn.vue"),
        },
        {
            path: '/Session/BillReports/Emergency/:SessionId',
            props: true,
            component: () => import("@/Session/BillReports/Emergency.vue"),
        },
        {
            path: '/Statutes/Constitution',
            redirect: '/Constitution',
        },
        {
            path: '/Statutes/Constitution/:Value',
            props: true,
            redirect: '/Constitution/:Value',
        },
        {
            path: '/Constitution',
            component: () => import("@/Constitution/Articles.vue"),
        },

        {
            path: '/Constitution/:Value',
            props: true,
            component: () => import("@/Constitution/Article.vue"),
        },


        {
            path: '/Statutes/:Value',
            props: true,
            component: () => import("@/Statutes/Statute.vue"),
        },
        {
            path: '/Statutes/Codified_Laws/:Value',
            redirect: '/Statutes/:Value',
            props: true,
        },
        {
            path: '/Statutes',
            component: () => import("@/Statutes/Statutes.vue"),
        },
        {
            path: '/Statutes/Codified_Laws',
            redirect: '/Statutes',
        },
        {
            path: '/Statutes/Session_Laws/:SessionId',
            props: true,
            component: () => import("@/SessionLaws/SessionLaws.vue"),
        },
        {
            path: '/Statutes/Session_Laws/Chapter/:SessionLawId',
            props: true,
            component: () => import("@/SessionLaws/SessionLawChapter.vue"),
        },


        {
            path: '/Statutes/Session_Laws/Foreword/:SessionId',
            props: route => ({
                SessionId: route.params.SessionId,
                DocumentTypeId: 60
            }),
            component: () => import("@/SessionLaws/SessionLawPart.vue"),
        },
        {
            path: '/Statutes/Session_Laws/CrossReference/:SessionId',
            props: route => ({
                SessionId: route.params.SessionId,
                DocumentTypeId: 61
            }),
            component: () => import("@/SessionLaws/SessionLawPart.vue"),
        },
        {
            path: '/Statutes/Session_Laws/TopicalIndex/:SessionId',
            props: route => ({
                SessionId: route.params.SessionId,
                DocumentTypeId: 62
            }),
            component: () => import("@/SessionLaws/SessionLawPart.vue"),
        },
        {
            path: '/Statutes/Session_Laws/TableOfContents/:SessionId',
            props: route => ({
                SessionId: route.params.SessionId,
                DocumentTypeId: 59
            }),
            component: () => import("@/SessionLaws/SessionLawPart.vue"),
        },
        {
            path: '/Statutes/Session_Laws/SDCLIndex/:SessionId',
            props: route => ({
                SessionId: route.params.SessionId,
                DocumentTypeId: 65
            }),
            component: () => import("@/SessionLaws/SessionLawPart.vue"),
        },


        {
            path: '/Rules/Administrative',
            component: () => import("@/Rules/Rules.vue"),
        },
        {
            path: '/Rules/Administrative/:Value',
            props: true,
            component: () => import("@/Rules/Rule.vue"),
        },
        {
            path: '/Rules/Registers/:SessionId',
            props: true,
            component: () => import("@/Rules/Registers.vue"),
        },
        {
            path: '/Rules/RulesManual',
            component: () => import("@/Rules/RulesManual.vue"),
        },
        {
            path: '/Legislators/Contacts/:SessionId',
            props: true,
            component: () => import("@/Legislators/Contacts.vue"),
        },
        {
            path: '/Legislators/Profile/:SessionMemberId',
            props: true,
            component: () => import("@/Legislators/Profile/Profile.vue"),
            children: [
                {
                    path: '',
                    redirect: 'Detail'
                },
                {
                    path: 'Detail',
                    props: true,
                    component: () => import("@/Legislators/Profile/Detail.vue"),
                },
                {
                    path: 'Committees',
                    props: true,
                    component: () => import("@/Legislators/Profile/Committees.vue"),
                },
                {
                    path: 'Bills',
                    props: true,
                    component: () => import("@/Legislators/Profile/Bills.vue"),
                },
                {
                    path: 'Hearings',
                    props: true,
                    component: () => import("@/Legislators/Profile/Hearings.vue"),
                },

            ],
        },
        {
            path: '/Legislators/Find',
            component: () => import("@/Legislators/Find.vue"),
        },
        {
            path: '/Legislators/Listing/:SessionId',
            props: true,
            component: () => import("@/Legislators/Listing.vue"),
        },
        {
            path: '/Historical',
            component: () => import("@/Legislators/Historical.vue"),
        },
        {
            path: '/Legislators/Historical',
            component: () => import("@/Legislators/Historical.vue"),
        },
        {
            path: '/Historical/Legislator/Profile/:MemberId',
            props: true,
            component: () => import("@/Legislators/HistoricalProfile.vue"),
        },
        {
            path: '/Historical/Reports/GeneralReports',
            component: () => import("@/Legislators/Reports/GeneralReports.vue"),
        },
        {
            path: '/Historical/Reports/Political',
            component: () => import("@/Legislators/Reports/Political.vue"),
        },
        {
            path: '/Historical/Reports/Statehood',
            component: () => import("@/Legislators/Reports/Statehood.vue"),
        },
        {
            path: '/Historical/Reports/Territorial',
            component: () => import("@/Legislators/Reports/Territorial.vue"),
        },
        {
            path: '/Historical/Reports/OfficeReports',
            component: () => import("@/Legislators/Reports/OfficeReports.vue"),
        },
        {
            path: '/Historical/Reports/Constitutional',
            component: () => import("@/Legislators/Reports/Constitutional.vue"),
        },
        {
            path: '/Historical/Reports/ExecutiveBoard',
            component: () => import("@/Legislators/Reports/ExecutiveBoard.vue"),
        },
        {
            path: '/Historical/Reports/SupremeCourt',
            component: () => import("@/Legislators/Reports/SupremeCourt.vue"),
        },
        {
            path: '/Historical/Reports/SpeakersPresidents',
            component: () => import("@/Legislators/Reports/SpeakersPresidents.vue"),
        },
        {
            path: '/Historical/Reports/USCongress',
            component: () => import("@/Legislators/Reports/USCongress.vue"),
        },
        {
            path: '/Historical/Reports/TenureReports',
            component: () => import("@/Legislators/Reports/TenureReports.vue"),
        },
        {
            path: '/Historical/Reports/TenYear',
            component: () => import("@/Legislators/Reports/TenYear.vue"),
        },
        {
            path: '/Historical/Reports/FifteenYear',
            component: () => import("@/Legislators/Reports/FifteenYear.vue"),
        },
        {
            path: '/Historical/Reports/TwentyYear',
            component: () => import("@/Legislators/Reports/TwentyYear.vue"),
        },
        {
            path: '/Historical/Reports/TwentyFiveYear',
            component: () => import("@/Legislators/Reports/TwentyFiveYear.vue"),
        },
        {
            path: '/Legislators/Archived',
            redirect: '/Session/Archived',
        },
        {
            path: '/Testify/:AgendaId',
            props: true,
            component: () => import("@/Committees/Testify.vue"),
        },
        {
            path: '/Session/General/:SessionId',
            props: true,
            component: () => import("@/Session/General.vue"),
        },
        {
            path: '/Session/Housing',
            alias: '/Session/General/Housing/:SessionId',
            props: true,
            component: () => import("@/Session/Housing.vue"),
        },
        {
            path: '/Session/Memorial',
            component: () => import("@/Session/Memorial.vue"),
        },
        {
            path: '/Session/Archived',
            component: () => import("@/Components/Archived.vue"),
        },
        {
            path: '/Session/Bills/:SessionId',
            props: true,
            component: () => import("@/Session/Bills.vue"),
        },
        {
            path: '/Session/Committees/:SessionId',
            props: true,
            component: () => import("@/Committees/Committees.vue"),
        },
        {
            path: '/Session/Committee/:CommitteeId',
            props: true,
            component: () => import('@/Committees/Committee.vue'),
            children: [
                {
                    path: '',
                    redirect: 'Detail'
                },
                {
                    path: 'Detail',
                    props: true,
                    component: () => import("@/Committees/Detail.vue"),

                },
                {
                    path: 'Agendas',
                    alias: 'Calendars',
                    props: true,
                    component: () => import("@/Committees/Agendas.vue")

                },
                {
                    path: 'Bills',
                    props: true,
                    component: () => import("@/Committees/Bills.vue"),

                },
                {
                    path: 'Minutes',
                    alias: 'Journals',
                    props: true,
                    component: () => import("@/Committees/Minutes.vue"),

                },
                {
                    path: 'HearingDocuments',
                    props: true,
                    component: () => import("@/Committees/Hearings.vue"),

                },
                {
                    path: 'CombinedAgendas',
                    props: true,
                    component: () => import("@/Committees/CombinedAgendas.vue"),

                },
                {
                    path: 'eMinutes/:Avid',
                    props: true,
                    component: () => import("@/Committees/eMinutes.vue"),

                },
                {
                    path: 'eMinutes',
                    props: true,
                    component: () => import("@/Committees/eMinutes.vue"),

                },
            ],
        },
        {
            path: '/Session/Committee/LiveBoard/:DocumentId',
            props: true,
            component: () => import("@/Components/LiveBoard.vue"),
        },
        {
            path: '/Interim/Committee/LiveBoard/:DocumentId',
            props: true,
            component: () => import("@/Components/LiveBoard.vue"),
        },
        {
            path: '/Interim/Committee/:CommitteeId',
            props: true,
            component: () => import('@/Committees/Committee.vue'),
            children: [
                {
                    path: '',
                    redirect: 'Detail'
                },
                {
                    path: 'Detail',
                    props: true,
                    component: () => import("@/Committees/Detail.vue"),

                },
                {
                    path: 'Agendas',
                    props: true,
                    component: () => import("@/Committees/Agendas.vue"),

                },
                {
                    path: 'Bills',
                    props: true,
                    component: () => import("@/Committees/Bills.vue"),

                },
                {
                    path: 'Minutes',
                    props: true,
                    component: () => import("@/Committees/Minutes.vue"),

                },
                {
                    path: 'HearingDocuments',
                    props: true,
                    component: () => import("@/Committees/Hearings.vue"),

                },
                {
                    path: 'CombinedAgendas',
                    props: true,
                    component: () => import("@/Committees/CombinedAgendas.vue"),

                },
            ],
        },
        {
            path: '/Session/ConferenceCommittees/:SessionId',
            props: true,
            component: () => import("@/Session/ConferenceCommittees.vue"),
        },
        {
            path: '/Session/General/:SessionId',
            props: true,
            component: () => import("@/Session/General.vue"),
        },
        {
            path: '/Session/Schedule/:SessionId?',
            props: true,
            component: () => import("@/Components/Schedule.vue"),
        },
        {
            path: '/Interim/Schedule/:SessionId?',
            props: true,
            component: () => import("@/Components/Schedule.vue"),
        },
        {
            path: '/Interim/Committees/:SessionId',
            props: true,
            component: () => import("@/Committees/Committees.vue"),
        },
        {
            path: '/Interim/Archived',
            component: () => import("@/Components/Archived.vue"),
        },
        {
            path: '/Budget/Documents/:DocumentHearingId',
            props: true,
            component: () => import("@/Budget/Documents.vue"),
        },
        {
            path: '/Budget/Documents',
            props: true,
            component: () => import("@/Budget/Documents.vue"),
        },
        {
            path: '/Budget/MonthlyReport',
            component: () => import("@/Budget/MonthlyReport.vue"),
        },
        {
            path: '/Budget/GeneralFundReceipts',
            component: () => import("@/Budget/MonthlyReport.vue"),
        },
        {
            path: '/Budget/Expenditures',
            component: () => import("@/Budget/MonthlyReport.vue"),
        },
        {
            path: '/Budget/MedicalServices',
            component: () => import("@/Budget/MonthlyReport.vue"),
        },
        {
            path: '/Budget/TrustFunds',
            component: () => import("@/Budget/MonthlyReport.vue"),
        },
        {
            path: '/Budget/PrisonPopulations',
            component: () => import("@/Budget/MonthlyReport.vue"),
        },
        {
            path: '/Budget/Archived',
            component: () => import("@/Budget/Archived.vue"),
        },
        {
            path: '/Budget/Resources',
            component: () => import("@/Budget/Resources.vue"),
        },
        {
            path: '/Budget/Resources',
            component: () => import("@/Budget/Resources.vue"),
        },
        {
            path: '/Budget/GOACDocuments',
            component: () => import("@/Budget/Resources.vue"),
        },
        {
            path: '/Budget/GeneralFundReversions',
            component: () => import("@/Budget/Resources.vue"),
        },
        {
            path: '/Budget/PersonalServiceDocuments',
            component: () => import("@/Budget/Resources.vue"),
        },
        {
            path: '/Budget/LettersOfIntent',
            component: () => import("@/Budget/Resources.vue"),
        },
        {
            path: '/Budget/BFMReports',
            component: () => import("@/Budget/Resources.vue"),
        },
        {
            path: '/Students/Resources',
            component: () => import("@/References/PublicResources.vue"),
        },
        {
            path: '/Students/Page',
            component: () => import("@/Students/Page.vue"),
        },
        {
            path: '/Students/Intern',
            component: () => import("@/Students/Intern.vue"),
        },
        {
            path: '/Redistricting',
            redirect: '/Redistricting/Home'
        },
        {
            path: '/Redistricting/Home',
            component: () => import("@/Redistricting/Redistricting.vue"),
        },
        {
            path: '/Redistricting/CourtCases',
            component: () => import("@/Redistricting/CourtCases.vue"),
        },
        {
            path: '/Redistricting/Faq',
            component: () => import("@/Redistricting/Faq.vue"),
        },
        {
            path: '/Redistricting/Laws',
            component: () => import("@/Redistricting/Laws.vue"),
        },
        {
            path: '/Redistricting/Resources',
            component: () => import("@/Redistricting/Resources.vue"),
        },
        {
            path: '/References/IssueMemos',
            component: () => import("@/References/IssueMemos.vue"),
        },
        {
            path: '/References/Maps',
            component: () => import("@/References/Maps.vue"),
        },
        {
            path: '/References/PerformanceReports',
            component: () => import("@/References/PerformanceReports.vue"),
        },
        {
            path: '/References/ProgramEvaluation',
            component: () => import("@/References/ProgramEvaluation.vue"),
        },
        {
            path: '/References/RequiredReports',
            component: () => import("@/References/RequiredReports.vue"),
        },
        {
            path: '/References/StatisticalComparison',
            component: () => import("@/References/StatisticalComparison.vue"),
        },
        {
            path: '/References/ExternalResources',
            component: () => import("@/References/ExternalResources.vue"),
        },
        {
            path: '/References/PublicResources',
            component: () => import("@/References/PublicResources.vue"),
        },
        {
            path: '/PressRelease',
            component: () => import("@/Components/PressRelease.vue"),
        },
        {
            path: '/Disclaimer',
            component: () => import("@/Components/Disclaimer.vue"),
        },
        {
            path: '/Staff',
            component: () => import("@/Staff/Default.vue"),
        },
        {
            path: '/Staff/Profile/:UserId',
            props: true,
            component: () => import("@/Staff/Profile.vue"),
        },
        {
            path: '/Staff/Listing',
            component: () => import("@/Staff/Listing.vue"),
        },
        {
            path: '/Staff/Division',
            component: () => import("@/Staff/Division.vue"),
        },
        {
            path: '/Staff/SubjectArea',
            component: () => import("@/Staff/SubjectArea.vue"),
        },
        {
            path: '/Staff/Session/Committee',
            component: () => import("@/Staff/Committee.vue"),
        },
        {
            path: '/Staff/Interim/Committee',
            component: () => import("@/Staff/Committee.vue"),
        },
        {
            path: '/Staff/BudgetAgency',
            component: () => import("@/Staff/BudgetAgency.vue"),
        },
        {
            path: '/Staff/RuleAgency',
            component: () => import("@/Staff/RuleAgency.vue"),
        },
        {
            path: '/Staff/Careers',
            component: () => import("@/Staff/Careers.vue"),
        },
        {
            path: '/ContactUs',
            component: () => import("@/ContactUs/Information.vue"),
        },
        {
            path: '/ContactUs/Congress',
            component: () => import("@/ContactUs/Congress.vue"),
        },
        {
            path: '/ContactUs/ADARequest',
            component: () => import("@/ContactUs/ADARequest.vue"),
        },
        {
            path: '/Session/Vote/:VoteId',
            props: true,
            component: () => import("@/Session/Vote.vue"),
        },
        {
            path: '/400',
            component: () => import("@/ErrorPages/Error400.vue"),
        },
        {
            path: '/404',
            component: () => import("@/ErrorPages/Error404.vue"),
        },
        {
            path: '/500',
            component: () => import("@/ErrorPages/Error500.vue"),
        },
        {
            path: '/:pathMatch(.*)*',
            beforeEnter() { window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname); },
        },
    ],
    scrollBehavior(to, from, savedPosition)
    {
        if (savedPosition)
        {
            let ss = null;

            try
            {
                ss = window.sessionStorage;
            }
            catch { }

            if (ss)
            {
                var scrollPositions = ss['scrollPositions'] || '[]';

                var temp = JSON.parse(scrollPositions);
                var path = new URL(app.baseURI).pathname;
                if (temp && temp.some(x => x.path == path))
                {
                    var target = temp.find(x => x.path == path);
                    return new Promise((resolve, reject) =>
                    {
                        setTimeout(_ =>
                        {
                            let element = document.getElementById('scrolling-techniques');

                            if (element)
                            {
                                element.scrollTop = target.y;
                            }

                            resolve({ x: 0, y: 0 });
                        }, 1500);
                    });
                }
            }

            return { x: 0, y: 0 };
        }
        else
        {
            return { x: 0, y: 0 };
        }
    },
});